<template>
  <div id="container-div">
    <!-- <b-table-lite
      id="content-table"
      responsive
      :items="chunkItemRequests"
      :fields="['no', { key: 'itemsMaster', label: 'item description' }, { key: 'quantity', label: 'QTY' }, { key: 'unit', label: 'Unit' }, { key: 'totalCost', label: 'Est. total cost (S$)' }]"
    >
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(itemsMaster)="data">
        <b-card-text class="font-weight-bold mb-25">
          {{ data.item.itemsMaster }}
        </b-card-text>
        <b-card-text class="text-nowrap">
          {{ data.item.description }}
        </b-card-text>
      </template>
    </b-table-lite> -->
    <table
      style="page-break-after: auto;"
      class="pdf__table"
    >
      <thead style="display: table-header-group;">
        <tr>
          <th>No</th>
          <th>item description</th>
          <th>QTY</th>
          <th>Unit</th>
          <th>Est. total cost (S$)</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in chunkItemRequests"
          :key="index"
          style="page-break-inside: avoid; page-break-after: auto;"
        >
          <td style="page-break-inside: avoid; page-break-after: auto;">
            {{ index + 1 }}
          </td>
          <td style="page-break-inside: avoid; page-break-after: auto; width: 500px;">
            <div style="page-break-inside: avoid; page-break-after: auto;">
              <strong>{{ item.itemsMaster }}</strong>
              <br>
              <span class="text-pre">{{ item.description }}</span>
            </div>
          </td>
          <td style="page-break-inside: avoid; page-break-after: auto;">
            {{ item.quantity }}
          </td>
          <td style="page-break-inside: avoid; page-break-after: auto;">
            {{ item.unit }}
          </td>
          <td style="page-break-inside: avoid; page-break-after: auto;">
            {{ numberFormat(item.totalCost) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {} from 'bootstrap-vue'

export default {
  components: {
    // BCardText,
    // BTableLite,
  },
  props: {
    chunkItemRequests: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    elHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  updated() {
    // this.$nextTick(() => {
    //   this.$emit('update:elHeight', this.$el.clientHeight)
    // })
    // setTimeout(() => this.$emit('update:elHeight', this.$el.clientHeight), 1000)
    this.$emit('update:elHeight', this.$el.clientHeight)
  },
}
</script>
